import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEmployerAttribute from "../components/AddEmployerAttribute";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

async function fetchEmployerAttributes() {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/employer/attributes`
  );
  return response;
}

export default function EmployerAttributesConfig() {
  const [previousValues, setPreviousValues] = useState({});
  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const updateEmployeeAttributeMutation = useMutation(
    (values) => {
      return HttpService.getAxiosClient().put(
        window.API_URL + `/employer/attributes/${values.id}`,
        values.row
      );
    },
    {
      onSuccess: (data, value) => {
        setPreviousValues((prevPreviousValues) => {
          const newPreviousValues = { ...prevPreviousValues };
          delete newPreviousValues[value.id];
          return newPreviousValues;
        });
      },
      onError: (error) => {
        setFeedback({
          open: true,
          type: "error",
          message: "Error updating the attributes",
        });
      },
    }
  );

  const deleteEmployeeAttributeMutation = useMutation(
    (values) => {
      return HttpService.getAxiosClient().delete(
        window.API_URL + `/employer/attributes/${values.id}`
      );
    },
    {
      onSuccess: (dataResponse, value) => {
        data.data = data.data.filter((row) => row.id !== value.id);
      },
      onError: (error) => {
        setFeedback({
          open: true,
          type: "error",
          message: "Error deleting the attribute",
        });
      },
    }
  );

  const handleEditCellChange = (params, id, field, oldValue, newValue) => {
    const encryptedProperty =
      params.row.properties !== null &&
      Array.isArray(params.row.properties) &&
      params.row.properties.some(
        (property) => property.property === "ENCRYPTED"
      );
    if (!encryptedProperty) {
      if (newValue !== undefined && oldValue !== newValue) {
        setPreviousValues((prevPreviousValues) => ({
          ...prevPreviousValues,
          [id]: { ...prevPreviousValues[id], [field]: oldValue },
        }));
      }
    }
  };

  const clearEditedRow = (params) => {
    params.row.value = previousValues[params.id].value;
    setPreviousValues((prevPreviousValues) => {
      const newPreviousValues = { ...prevPreviousValues };
      delete newPreviousValues[params.id];
      return newPreviousValues;
    });
  };

  const isEncrypted = (params) => {
    return (
      params.row.properties !== null &&
      Array.isArray(params.row.properties) &&
      params.row.properties.some(
        (property) => property.property === "ENCRYPTED"
      )
    );
  };

  const columns = [
    { field: "id", headerName: "id", width: 10 },
    { field: "employerName", headerName: "Employer", width: 200, hide: false },
    { field: "attributeName", headerName: "Attribute", width: 350 },
    {
      field: "value",
      headerName: "Value",
      width: 350,
      editable: (params) => !isEncrypted(params),
      renderCell: (params) => {
        if (params.row.isVisible === undefined) {
          params.row.isVisible = false;
        }

        const handleToggleVisibility = () => {
          params.row.isVisible = !params.row.isVisible;
          params.api.forceUpdate(); // Use forceUpdate to refresh the cell
        };
        if (isEncrypted(params)) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {params.row.isVisible ? (
                <span>{params.row.value}</span>
              ) : (
                <Visibility fontSize="16" onClick={handleToggleVisibility} />
              )}
              {params.row.isVisible && (
                <VisibilityOff
                  sx={{ marginLeft: "0.5rem" }}
                  fontSize="16"
                  onClick={handleToggleVisibility}
                />
              )}
            </div>
          );
        } else {
          return params.row.value;
        }
      },
    },
    {
      field: "lastChangedDate",
      headerName: "Last changed",
      width: 150,
      hide: true,
    },
    { field: "changedBy", headerName: "Changed by", width: 120, hide: true },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            key={"action." + params.id}
            style={{ display: isEncrypted(params) ? "none" : "flex" }}
          >
            <IconButton
              disabled={!(params.id in previousValues) || isEncrypted(params)}
              key={"save." + params.id}
              onClick={() => {
                updateEmployeeAttributeMutation.mutate({ ...params });
              }}
            >
              <SaveIcon />
            </IconButton>
            {params.id in previousValues ? (
              <IconButton
                key={"clear." + params.id}
                onClick={() => {
                  clearEditedRow(params);
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <>
                <IconButton
                  disabled={params.id in previousValues}
                  key={"toggle." + params.id}
                  onClick={() => {
                    const newStatus =
                      params.row.status !== "ACTIVE" ? "ACTIVE" : "INACTIVE";
                    params.row.status = newStatus;
                    updateEmployeeAttributeMutation.mutate({ ...params });
                  }}
                >
                  {params.row.status !== "ACTIVE" ? (
                    <ToggleOffOutlinedIcon />
                  ) : (
                    <ToggleOnIcon />
                  )}
                </IconButton>
                <IconButton
                  disabled={params.id in previousValues}
                  key={"delete." + params.id}
                  onClick={() => {
                    deleteEmployeeAttributeMutation.mutate({ ...params });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const { data, isLoading } = useQuery(
    ["employerAttributes"],
    fetchEmployerAttributes
  );
  const [addAttributeDlg, setAddAttributeDlg] = useState(false);

  const addAttributelgFunct = (event) => {
    return (
      <Dialog open={addAttributeDlg} fullWidth onClose={() => {}}>
        <DialogActions
          style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
        >
          <div style={{ width: "100%" }}>
            <DialogTitle>
              <Typography>Add Employee attribute</Typography>
            </DialogTitle>
          </div>
          <IconButton
            onClick={() => {
              setAddAttributeDlg(false);
            }}
          >
            <CloseIcon variant="Outlined" />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <AddEmployerAttribute handleClose={() => setAddAttributeDlg(false)} />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {isLoading ? (
        ""
      ) : (
        <Grid container p={0} m={0} spacing={0}>
          <Grid item xs={12}>
            <Box display="flex">
              <Typography variant="h5">Company Attributes</Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setAddAttributeDlg(true)}
              >
                Add Attribute
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ width: "100%", height: "80vh" }}>
            <DataGrid
              key={data.data.id}
              rows={data.data}
              columns={columns}
              getRowHeight={() => 25}
              density="compact"
              onCellEditStop={(params, event) =>
                handleEditCellChange(
                  params,
                  params.id,
                  params.field,
                  params.value,
                  event?.target?.value
                )
              }
              disableDensitySelector
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              isCellEditable={(params) => !isEncrypted(params)}
            />
          </Grid>
        </Grid>
      )}
      {addAttributeDlg ? addAttributelgFunct() : ""}
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback({ ...feedback, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFeedback({ ...feedback, open: false })}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
