import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEmployerAttribute from "../components/AddEmployerAttribute";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AddEmployer from "../components/AddEmployer";

async function fetchEmployer() {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/employer/all`
  );
  return response;
}

export default function EmployerConfig() {
  const [previousValues, setPreviousValues] = useState({});
  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const updateEmployeeAttributeMutation = useMutation(
    (values) => {
      return HttpService.getAxiosClient().put(
        window.API_URL + `/employer/attributes/${values.id}`,
        values.row
      );
    },
    {
      onSuccess: (data, value) => {
        setPreviousValues((prevPreviousValues) => {
          const newPreviousValues = { ...prevPreviousValues };
          delete newPreviousValues[value.id];
          return newPreviousValues;
        });
      },
      onError: (error) => {
        setFeedback({
          open: true,
          type: "error",
          message: "Error updating the attributes",
        });
      },
    }
  );

  const deleteEmployeeAttributeMutation = useMutation(
    (values) => {
      return HttpService.getAxiosClient().delete(
        window.API_URL + `/employer/attributes/${values.id}`
      );
    },
    {
      onSuccess: (dataResponse, value) => {
        data.data = data.data.filter((row) => row.id !== value.id);
      },
      onError: (error) => {
        setFeedback({
          open: true,
          type: "error",
          message: "Error deleting the attribute",
        });
      },
    }
  );

  const handleEditCellChange = (params, id, field, oldValue, newValue) => {
    const encryptedProperty =
      params.row.properties !== null &&
      Array.isArray(params.row.properties) &&
      params.row.properties.some(
        (property) => property.property === "ENCRYPTED"
      );
    if (!encryptedProperty) {
      if (newValue !== undefined && oldValue !== newValue) {
        setPreviousValues((prevPreviousValues) => ({
          ...prevPreviousValues,
          [id]: { ...prevPreviousValues[id], [field]: oldValue },
        }));
      }
    }
  };

  const clearEditedRow = (params) => {
    params.row.value = previousValues[params.id].value;
    setPreviousValues((prevPreviousValues) => {
      const newPreviousValues = { ...prevPreviousValues };
      delete newPreviousValues[params.id];
      return newPreviousValues;
    });
  };

  const isEncrypted = (params) => {
    return (
      params.row.properties !== null &&
      Array.isArray(params.row.properties) &&
      params.row.properties.some(
        (property) => property.property === "ENCRYPTED"
      )
    );
  };

  const columns = [
    { field: "idEmployer", headerName: "id", width: 100 },
    { field: "name", headerName: "Employer", width: 200, hide: false },
    { field: "description", headerName: "Description", width: 350 },

    {
      field: "lastChangedDate",
      headerName: "Last changed",
      width: 150,
      hide: true,
    },
    { field: "changedBy", headerName: "Changed by", width: 120, hide: true },
  ];

  const { data, isLoading } = useQuery(["employer"], fetchEmployer);
  const [addEmployerDlg, setAddEmployerDlg] = useState(false);

  const addEmployerlgFunct = (event) => {
    return (
      <Dialog open={addEmployerDlg} fullWidth onClose={() => {}}>
        <DialogActions
          style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
        >
          <div style={{ width: "100%" }}>
            <DialogTitle>
              <Typography>Add Company</Typography>
            </DialogTitle>
          </div>
          <IconButton
            onClick={() => {
              setAddEmployerDlg(false);
            }}
          >
            <CloseIcon variant="Outlined" />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <AddEmployer handleClose={() => setAddEmployerDlg(false)} />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {isLoading ? (
        ""
      ) : (
        <Grid container p={0} m={0} spacing={0}>
          <Grid item xs={12}>
            <Box display="flex">
              <Typography variant="h5">Companies </Typography>
              <Button
                variant="outlined"
                size="small"
                sx={{ marginLeft: "1rem" }}
                onClick={() => setAddEmployerDlg(true)}
              >
                Add Company
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ width: "100%", height: "80vh" }}>
            <DataGrid
              key={data.data.idEmployer}
              rows={data.data}
              columns={columns}
              getRowHeight={() => 25}
              density="compact"
              onCellEditStop={(params, event) =>
                handleEditCellChange(
                  params,
                  params.id,
                  params.field,
                  params.value,
                  event?.target?.value
                )
              }
              disableDensitySelector
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              isCellEditable={(params) => !isEncrypted(params)}
              getRowId={(row) => row.idEmployer}
            />
          </Grid>
        </Grid>
      )}
      {addEmployerDlg ? addEmployerlgFunct() : ""}
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback({ ...feedback, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFeedback({ ...feedback, open: false })}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
