import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  Snackbar,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import { useFormik } from "formik";
import * as yup from "yup";

export default function AddEmployer(props) {
  const createEmployeeAttributeMutation = useMutation(
    (values) => {
      return HttpService.getAxiosClient().put(
        window.API_URL + `/employer`,
        values
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("employer");
        props.handleClose();
      },
      onError: (error) => {
        setFeedback({
          open: true,
          type: "error",
          message: "Error creating the employer, constraint violated.",
        });
      },
    }
  );

  const validationSchema = yup.object({
    companyId: yup
      .string("Enter the company id")
      .required("Company id is required"),
    companyName: yup
      .string("Enter the name of the company")
      .required("Company name is required"),
    description: yup
      .string("Enter the description")
      .required("Description is required"),
    processorList: yup.array().min(1, "At least one processor is required"),
    bankList: yup.array().min(1, "At least one bank is required"),
  });

  const [enableSFTPCreation, setEnableSFTPCreation] = useState(false);

  const formik = useFormik({
    initialValues: {
      companyId: "",
      companyName: "",
      description: "",
      bank: "sutton",
      brand: "visa",
      processor: "visadps",
      sftpuser: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createEmployeeAttributeMutation.mutate({ ...values });
    },
  });

  const queryClient = useQueryClient();

  async function fetchCompanyData() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/employer/all`
    );
    return response;
  }

  const { data, isLoading } = useQuery(["companies"], () => fetchCompanyData());
  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const handleProcessorChange = (event, values) => {
    const newProcessor = event.target.value;
    formik.setFieldValue("processor", newProcessor);
    if (event.target.value === "visadps") {
      formik.setFieldValue("brand", "visa");
      formik.setFieldValue("bank", "sutton");
    }
  };

  const handleBrandChange = (event, values) => {
    const newBrand = event.target.value;
    formik.setFieldValue("brand", newBrand);
  };

  const handleBankChange = (event, values) => {
    const newBank = event.target.value;
    formik.setFieldValue("bank", newBank);
  };

  const handleNameChange = (event) => {
    const newName = event.target.value;
    formik.setFieldValue("companyName", newName);
    const formattedSftpuser =
      newName.toLowerCase().replace(/\s+/g, "") + "_sftpuser";
    formik.setFieldValue("sftpuser", formattedSftpuser);
  };

  return (
    <div>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        {isLoading ? (
          ""
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="companyId"
                label="Company Id"
                value={formik.values.companyId}
                onChange={formik.handleChange}
                error={
                  formik.touched.companyId && Boolean(formik.errors.companyId)
                }
                helperText={formik.touched.companyId && formik.errors.companyId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="companyName"
                label="Company Name"
                value={formik.values.companyName}
                onChange={handleNameChange}
                error={
                  formik.touched.companyName &&
                  Boolean(formik.errors.companyName)
                }
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="description"
                label="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Grid>
            <Grid
              container
              spacing={2}
              alignContent={"center"}
              alignItems={"center"}
              alignSelf={"center"}
              justifyContent={"center"}
              paddingLeft={"1rem"}
              paddingTop={"1rem"}
            >
              <Grid item xs={4}>
                <FormControl>
                  <FormLabel id="processor-radio-buttons-group-label">
                    Processor
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="processor-radio-buttons-group-label"
                    name="processor-radio-buttons-group"
                    onChange={handleProcessorChange}
                    value={formik.values.processor}
                  >
                    <FormControlLabel
                      value="visadps"
                      control={<Radio />}
                      label="Visa DPS"
                    />
                    <FormControlLabel
                      value="qolo"
                      control={<Radio />}
                      label="Qolo"
                    />
                    <FormControlLabel
                      value="highnote"
                      control={<Radio />}
                      label="Highnote"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <FormLabel id="brand-radio-buttons-group-label">
                    Brand
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="bank-radio-buttons-group-label"
                    name="brand-radio-buttons-group"
                    //onChange={handleBrandChange}

                    onChange={handleBrandChange}
                    value={formik.values.brand}
                  >
                    <FormControlLabel
                      value="visa"
                      control={<Radio />}
                      label="Visa"
                    />
                    <FormControlLabel
                      value="mastercard"
                      control={<Radio />}
                      label="Mastercard"
                      disabled={formik.values.processor === "visadps"}
                    />
                    <FormControlLabel
                      value="discover"
                      control={<Radio />}
                      label="Discover"
                      disabled={formik.values.processor === "visadps"}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <FormLabel id="bank-radio-buttons-group-label">
                    Bank
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="bank-radio-buttons-group-label"
                    name="bank-radio-buttons-group"
                    //onChange={handleBankChange}
                    onChange={handleBankChange}
                    value={formik.values.bank}
                  >
                    <FormControlLabel
                      value="sutton"
                      control={<Radio />}
                      label="Sutton Bank"
                    />
                    <FormControlLabel
                      value="republic"
                      control={<Radio disabled />}
                      label="Republic Bank"
                      disabled={formik.values.processor === "visadps"}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              alignContent={"center"}
              alignItems={"center"}
              alignSelf={"center"}
              justifyContent={"center"}
              paddingLeft={"1rem"}
              paddingTop={"1rem"}
            >
              <Grid item xs={5}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={() => {
                          setEnableSFTPCreation(!enableSFTPCreation);
                        }}
                      />
                    }
                    label="SFTP account"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={7}>
                {enableSFTPCreation ? (
                  <TextField
                    fullWidth
                    onFocus={(e) => e.target.select()}
                    disabled={!enableSFTPCreation}
                    id="sftpuser"
                    label="Desired SFTP username"
                    value={formik.values.sftpuser}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.sftpuser && Boolean(formik.errors.sftpuser)
                    }
                    helperText={
                      formik.touched.sftpuser && formik.errors.sftpuser
                    }
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} pt={3}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                disabled={createEmployeeAttributeMutation.isLoading}
              >
                SAVE
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback({ ...feedback, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFeedback({ ...feedback, open: false })}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
